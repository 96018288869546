/* .userLogin-img{
    height:100vh;
    background-size: cover;
} */

/* @media only screen and (max-width: 768px) {
    .yarya-img-ligtgrey{
        display:none;
    }
   .login-box-details{
    width:350px;
    position: relative;
    right:190px;
   }
   .bg-container-yaryaloga{   
    background-image: url('/public/img/Yarya Sekur V3-05.svg');
    height:1000px;
    background-size: cover;
   }
   
} */

.login-page-container{
    margin-left:550px;
    z-index: 1000;
    position: relative;
}

@media only screen and (max-width: 768px) {

    .maincontainer{
      background-image: url('/public/img/Yarya Sekur-02.svg');
      background-size:cover;
    }

    .login-page-container{                                         
        width:280px;
        margin-left:25px;
    }
    .image-container{
        display:none;
    }
   
}